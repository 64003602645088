<template>
    <v-container>
        <v-btn class="mb-4" @click="edit(null)">
            Neu <v-icon right small>fas fa-plus</v-icon>
        </v-btn>
        <v-expansion-panels v-model="selected" tile mandatory focusable>
            <v-expansion-panel v-if="editItem != null" :disabled="editLoading">
                <v-expansion-panel-header>
                    {{editItem.ID == null ? 'Erstellen' : `[${editItem.ID}] '${editItem.title}' bearbeiten`}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form v-model="editValid">
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field v-model="editItem.title" :rules="rule_notempty" label="Titel" required></v-text-field>
                                    <v-datetime-picker v-model="editItem.published" :rules="rule_notempty" label="Veröffentlicht" required/>
                                    <!--v-card tile color="box_accent" class="px-2 pt-2">
                                        <v-img height="360px" :src="image_preview" contain />
                                        <v-file-input :rules="image_rules" v-model="editItem.image_upload" accept="image/png, image/jpeg" prepend-icon="" placeholder="Bild" class="pa-0 mb-0 mt-5" show-size />
                                    </v-card-->
                                    <axz-file-picker v-model="editItem.image" :rules="file_rules" label="Bild" accept="image/*"></axz-file-picker>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="editItem.short" :rules="rule_notempty" label="Short" required></v-text-field>
                                    <v-textarea v-model="editItem.text" :rules="rule_notempty" label="Text" auto-grow required></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row justify="end" class="mt-4">
                                <v-btn v-if="editItem.ID == null" @click="create()" :loading="editLoading" :disabled="!editValid">
                                    Anlegen <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                                <v-btn v-else @click="update()" :loading="editLoading" :disabled="!editValid">
                                    Speichern <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                            </v-row>
                            
                        </v-container>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    News
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-data-table :headers="cols_news" :items="$db.news.data" item-key="ID" :items-per-page="-1" dense :hide-default-footer="true" show-expand single-expand group-by="isPublishedAndOnline" :loading="loading">
                        <template v-slot:item.online="{ item }">
                            <v-icon v-if="item.online == 1" small  @click="setOnline(item, false)">fas fa-eye</v-icon>
                            <v-icon v-else small color="grey" @click="setOnline(item, true)">fas fa-eye-slash</v-icon>
                        </template>
                        <template v-slot:item.internal="{ item }">
                            <v-icon v-if="item.internal == 1" small color="grey" @click="setInternal(item, false)">fas fa-lock</v-icon>
                            <v-icon v-else small @click="setInternal(item, true)">fas fa-unlock</v-icon>
                        </template>
                        <template v-slot:item.created="{ item }">
                            <span>
                                {{UTCtoLocal(item.created)}}
                            </span>
                        </template>
                        <template v-slot:item.published="{ item }">
                            <span :class="item.isPublished ? '' : 'grey--text'">
                                {{UTCtoLocal(item.published)}}
                            </span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="ml-2" @click="edit(item)">fas fa-pen</v-icon>
                        </template>
                        <template v-slot:group.header="{ headers, group }">
                            <td :colspan="headers.length" class="text-caption text-uppercase pl-2"><v-icon small class="mr-2">fas fa-{{group == 1 ? 'globe' : 'pencil-ruler'}}</v-icon>{{group == 1 ? 'Veröffentlicht' : 'Unveröffentlicht'}}</td>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-row no-gutters>
                                    <v-col cols="2">
                                        <v-img :src="item.image" contain width="250px" class="pr-4 pt-12"/>
                                    </v-col>
                                    <v-col>
                                        <v-textarea v-model="item.text" readonly auto-grow></v-textarea>
                                    </v-col>
                                </v-row>
                            </td>
                        </template>
                        <template v-slot:footer>
                            <v-row justify="center">
                                <v-col class="text-center"><v-icon small left>fas fa-eye</v-icon> = Online</v-col>
                                <v-col class="text-center"><v-icon small left color="grey">fas fa-eye-slash</v-icon> = Offline</v-col>
                                <v-col class="text-center"><v-icon small left color="grey">fas fa-lock</v-icon> = Intern</v-col>
                                <v-col class="text-center"><v-icon small left>fas fa-unlock</v-icon> = Extern</v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            cols_news: [
                //{ text: 'ID', value: 'ID' },
                { text: 'Online', value: 'online' },
                { text: 'Intern', value: 'internal' },
                { text: 'Überschrift', value: 'title' },
                { text: 'Kurzfassung', value: 'short' },
                { text: 'Ersteller', value: 'writer' },
                //{ text: 'text', value: 'text' },
                { text: 'Erstellt', value: 'created' },
                { text: 'Veröffentlicht', value: 'published' },
                { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
                { text: '', value: 'data-table-expand' },
            ],
            published_news: [],
            news: [],
            selected: 0,
            editItem: null,
            editValid: false,
            editLoading: false,
            file_rules: [
                value => !!value && value.size < 20000000 || 'File size should be less than 20 MB!',
            ],
            rule_notempty: [
                v => !!v || 'required',
            ],
        }
    },
    computed: {
        image_preview () {
            if(this.editItem.image_upload != null)
                return URL.createObjectURL(this.editItem.image_upload);
            else if(this.editItem.image != null)
                return this.editItem.image;
            else return null;
        }
    },
    watch: {
        selected () {
            if(this.selected != 1 && this.editItem != null){
                this.editItem = null;
                this.loadNews(); //or clone edit item !=??!?!?
            }
        }
    },
    methods: {
        loadNews(){
            this.loading = true;
            this.$db.news.load().then(() => {
                this.loading = false;
            });
        },
        edit(item) {
            if(item != null) {
                this.editItem = item;
            }
            else {
                this.editItem = {
                    ID: null,
                    title: null,
                    short: null,
                    text: null,
                    published: null,
                    image: null,
                }
            }
            this.selected = 1;
        },
        setOnline(item, state) {
            this.$db.news.setOnline(item.ID, state ? 1 : 0).then(() => {
                this.loadNews();
            });
        },
        setInternal(item, state) {
            this.$db.news.setInternal(item.ID, state ? 1 : 0).then(() => {
                this.loadNews();
            });
        },
        async create(){
            this.editLoading = true;
            this.$db.news.create(
                this.editItem.title,
                this.editItem.short,
                this.editItem.text,
                this.editItem.published,
                await this.$db.files.create(this.editItem.image)
            ).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.loadNews();
            });
        },
        async update(){
            this.editLoading = true;
            this.$db.news.update(
                this.editItem.ID,
                this.editItem.title,
                this.editItem.short,
                this.editItem.text,
                this.editItem.published,
                typeof this.editItem.image == 'object' ? await this.$db.files.createOrUpdate(this.editItem.image.identifier, this.editItem.image) : this.editItem.image
            ).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.loadNews();
            });
        }
    },
    mounted() {
        this.loadNews();
    }
}
</script>